var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('CCard',[_c('CCardHeader',[_c('CRow',[_c('CCol',{attrs:{"md":"7"}},[_c('h4',[_vm._v("Users")])]),_c('CCol',{attrs:{"md":"5"}},[_c('CInput',{attrs:{"label":"Search","placeholder":"Type to Search","horizontal":""},on:{"keyup":_vm.filterChange},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('span',{staticStyle:{"color":"green","font-style":"italic"}},[_vm._v("* Search by Name or Phone Number (eg. 6580134177).")])],1)],1)],1),_c('br'),_c('CCardBody',[_c('div',{staticClass:"text-center"},[(_vm.spinner)?_c('b-spinner',{attrs:{"variant":"success","label":"Spinning"}}):_vm._e()],1),_c('div',{staticClass:"table-responsive table"},[_c('b-table',{attrs:{"sticky-header":"","striped":"","hover":"","items":_vm.items,"fields":_vm.fields,"filter":_vm.filter},scopedSlots:_vm._u([{key:"cell(userIdentify.userId)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("IdSubstr")(item.userIdentify.userId))+" ")]}},{key:"cell(createDate)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateSubstr")(item.createDate))+" ")]}},{key:"cell(userStatus)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("lower")(item.userStatus))+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{attrs:{"variant":"info","to":"/eWallet/details/" + item.userIdentify.userId +"/"+ item.id}},[_vm._v("Detail")])]}}])})],1),_c('div',[_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('b-pagination',{attrs:{"size":"md","total-rows":_vm.count,"per-page":_vm.pageSize},on:{"change":_vm.handlePageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('b-col',{attrs:{"sm":"9"}},[_c('b-form-select',{staticStyle:{"width":"100px"},on:{"change":function($event){return _vm.handlePageSizeChange($event)}},model:{value:(_vm.pageSize),callback:function ($$v) {_vm.pageSize=$$v},expression:"pageSize"}},_vm._l((_vm.pageSizes),function(size){return _c('option',{key:size,domProps:{"value":size}},[_vm._v(" "+_vm._s(size)+" ")])}),0)],1)],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }