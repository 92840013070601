<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CRow>
              <CCol md="7">
                    <h4>Users</h4>
              </CCol>
              <CCol md="5">
                   <CInput label="Search" @keyup="filterChange" v-model="filter" placeholder="Type to Search" horizontal />
                   <span style="color:green; font-style: italic;">* Search by Name or Phone Number (eg. 6580134177).</span>
              </CCol>
          </CRow>
        </CCardHeader><br/>
         <CCardBody> 
        
          <div class="text-center">
            <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
          </div>

          <div class="table-responsive table">
            <b-table sticky-header striped hover  :items="items" :fields="fields" :filter="filter">

                <template v-slot:cell(userIdentify.userId)="{ item }"> 
                      {{ item.userIdentify.userId | IdSubstr }}
                </template>

                <template v-slot:cell(createDate)="{ item }"> 
                      {{ item.createDate | dateSubstr }}
                </template>

                <template v-slot:cell(userStatus)="{ item }"> 
                      {{ item.userStatus | lower }}
                </template>

                <template v-slot:cell(actions)="{ item }"> 
                    <b-button variant="info" :to='"/eWallet/details/" + item.userIdentify.userId +"/"+ item.id' >Detail</b-button>
                </template>
                
            </b-table>
          </div>

          <div>
            <b-row>
              <b-col sm="3">
                <b-pagination size="md" 
                    :total-rows="count" 
                    :per-page="pageSize" 
                    v-model="page" 
                    @change="handlePageChange"
                />
              </b-col>       
              <b-col sm="9">
                <!-- <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
                </b-form-select> -->
                <b-form-select v-model="pageSize"  style="width:100px;" @change="handlePageSizeChange($event)">
                <option v-for="size in pageSizes" :key="size" :value="size">
                    {{ size }}
                </option>
                </b-form-select>
              </b-col>
            </b-row>      
          </div>
          
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import AuthService from '@/api/AuthService.js';

 export default {
    name: "users",
    data() {
      return {
        items:[], 
        currentItem:null,
        currentIndex:-1, 
        searchTitle: "",           
        fields: [   
          {
            name : 'userId',
            key: 'userIdentify.userId',
            label : 'User ID',
            sortable: true
          },  
          {
            key: 'displayName',
            label : 'Display Name',
            sortable: true
          },                     
          {
            key: 'firstName',
            label : 'First Name',
            sortable: true
          },         
          {
            key: 'lastName',
            label : 'Last Name',
            sortable: true
          },           
          {
            key: 'mobileNumber.countryCode',
            label : 'Country Code',
            sortable: false
          },         
          {
            key: 'mobileNumber.number',
            label : 'Phone No.',
            sortable: true
          }, 
          {
            key: 'emailAddress',
            label : 'Email',
            sortable: true
          },
          {
            key: 'createDate',
            label : 'Created Date',
            sortable: true
          },
          {
            key: 'userStatus',
            label : 'Status',
            sortable: false
          },          
          {            
            key: 'actions',
            label: 'Actions'         
          } 
        ],       
        page: 1,
        count:0,
        size:0,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100],
        filter: null,
        spinner:false,
      };
    },

    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },

        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)            
        },

        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'            
        }


    },

    created () {         
    },
    methods: { 

    filterChange(){
        if(this.filter.length > 3){
           this.retrieveUsers(); 
        }       
    },

    handlePageChange(value) {
      this.page = value;
      this.retrieveUsers();
    },

    handlePageSizeChange(event) { //console.log(event);
        this.pageSize = event; //event.target.value;
        this.page = 1;
        this.retrieveUsers();
    },
        
    getRequestParams(searchTitle, page, pageSize) {
        let params = {}; let strSearch = {}; 

        if (this.filter) {
            strSearch['searchString'] = this.filter;
            params["title"] = strSearch;
        }

        if (page) {
            params["page"] = page - 1;
        }

        if (pageSize) {
            params["size"] = pageSize;
        }

        return params;
    },

    retrieveUsers(){
         this.spinner = true;
         const params = this.getRequestParams(
            this.searchTitle,
            this.page,
            this.pageSize
         );

         //console.log(params);

          AuthService.getUserListV2(params).then(resp => {   //console.log(resp);       
            this.items = resp.payload; this.spinner = false;
            this.count = resp.totalElements;
          }, error => {
            this.spinner = false;
          });

    }

    },
    mounted() { 
       //console.log(process.env.VUE_APP_ROOT_API);
       this.retrieveUsers();
    },   
 }; 

</script>

<style>
    .table{ overflow-y:scroll; }
</style>